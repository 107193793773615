*{
    margin: 0;
    padding: 0;
}

.sub-wrapper{
    height: 5vh;
    width: 10vw;
    /* background-color: rgb(255, 146, 146); */
    display: flex;
    /* flex-direction: row ; */
    justify-content: center;
    align-items: center;
}
.sahpe-one {
    height: 30%;
    width: 15%;
    background-color: rgb(255, 123, 0);
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}
.sahpe-two {
    height: 30%;
    width: 15%;
    background-color:  rgb(255, 123, 0);
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}
.sahpe-three {
    height: 30%;
    width: 80%;
    background-color: rgb(255, 123, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    /* clip-path: polygon(92% 0%, 100% 50%, 92% 100%, 5% 100%, 15% 52%, 25% 0%); */
    clip-path: polygon( 92% 0%, 100% 50%, 92% 100%, 8% 100%, 0% 50%, 8% 0%);
}
.sahpe-four {
    height: 30%;
    width: 15%;
    background-color:  rgb(255, 123, 0);
    /* clip-path: polygon( 75% 0%, 100% 50%, 75% 100%, 10% 100%, 0% 50%, 9% 0%); */
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}
.sahpe-five {
    height: 30%;
    width: 15%;
    background-color:  rgb(255, 123, 0);
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}
.text{
    font-size: 8px;
    font-weight: bold;
    color: black;
}

  
  .borderbtm {
    font-size: 20px;
    border-bottom: 1px solid #feb637;
  }